export const testEmpty = (model, initialModel, keysToDelete = []) => {
  // console.log("model=", model);
  // console.log("initialModel=", initialModel);
  var keys = Object.keys(model);
  let result = true;
  if (keysToDelete != []) {
    keysToDelete.map((key) => {
      keys = keys.filter((el) => el != key);
    });

    if (model.useMed == false) {
      keys = keys.filter((el) => el != "medicTreatment");
    }
    console.log(keys);
    keys.forEach((element) => {
      if (model[element] == initialModel[element]) {
        result = false;
      }
    });
  } else {
    if (model.useMed == false) {
      keys.filter((el) => el != "medicTreatment");
    }
    keys.forEach((element) => {
      if (model[element] == initialModel[element]) {
        result = false;
      }
    });
  }

  return result;
};
