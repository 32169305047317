import React, { useEffect } from "react";
import { Checkbox, InputPicker, SelectPicker } from "rsuite";
import {
  BestInKounouz,
  branchTreatment,
  goodBadResponse,
  guide,
  heardOf,
  UmrahState,
  yesOrNoResponse,
} from "../../data/filterData";

const AdvancedSearch = ({ filterModel, _setfilterModel }) => {
  const setTrueOrFalseValue = (value) => {
    if (value == 1) return true;
    else if (value == 2) return false;
    else return null;
  };
  const fixValue = (value) => {
    if (value == true) return 1;
    else if (value == false) return 2;
    else return null;
  };

  return (
    <div>
      <div
        className="p-10"
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row-reverse",
        }}
      >
        <div
          style={{
            padding: 15,
          }}
        >
          <SelectPicker
            style={{ width: 150 }}
            data={yesOrNoResponse}
            value={fixValue(filterModel.isFirstTime)}
            onChange={(isFirstTime) =>
              _setfilterModel((prev) => ({
                ...prev,
                isFirstTime: setTrueOrFalseValue(isFirstTime),
              }))
            }
          />
          <span>{" أوّل مرّة "}</span>
        </div>

        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={yesOrNoResponse}
            value={fixValue(filterModel.isRecommended)}
            onChange={(isRecommended) =>
              _setfilterModel((prev) => ({
                ...prev,
                isRecommended: setTrueOrFalseValue(isRecommended),
              }))
            }
          />
          <span>{" اقتراح كنوز للنّاس"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={yesOrNoResponse}
            value={fixValue(filterModel.isCoupondUsed)}
            onChange={(isCoupondUsed) =>
              _setfilterModel((prev) => ({
                ...prev,
                isCoupondUsed: setTrueOrFalseValue(isCoupondUsed),
              }))
            }
          />
          <span>{" استعمال رمز التخفيض"}</span>
        </div>

        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={heardOf}
            value={filterModel.methodOfHearingAbout}
            onChange={(methodOfHearingAbout) =>
              _setfilterModel((prev) => ({ ...prev, methodOfHearingAbout }))
            }
          />
          <span>{": طريقة السّماع عن كنوز "}</span>
        </div>

        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={UmrahState}
            value={filterModel.generalReview}
            onChange={(generalReview) =>
              _setfilterModel((prev) => ({ ...prev, generalReview }))
            }
          />
          <span>{" الرّضاء على  العمرة "}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={branchTreatment}
            value={filterModel.branchReview}
            onChange={(branchReview) =>
              _setfilterModel((prev) => ({ ...prev, branchReview }))
            }
          />
          <span>{" الرّضاء على الفرع  "}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={guide}
            value={filterModel.guideReview}
            onChange={(guideReview) =>
              _setfilterModel((prev) => ({ ...prev, guideReview }))
            }
          />
          <span>{" مرافق "}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.airlineReview}
            onChange={(airlineReview) =>
              _setfilterModel((prev) => ({ ...prev, airlineReview }))
            }
          />
          <span>{" الطيران"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.madinahHotelReview}
            onChange={(madinahHotelReview) =>
              _setfilterModel((prev) => ({ ...prev, madinahHotelReview }))
            }
          />
          <span>{" فندق المدينة"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.madinahServiceReview}
            onChange={(madinahServiceReview) =>
              _setfilterModel((prev) => ({ ...prev, madinahServiceReview }))
            }
          />
          <span>{" خدمات المدينة"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.makkahHotelReview}
            onChange={(makkahHotelReview) =>
              _setfilterModel((prev) => ({ ...prev, makkahHotelReview }))
            }
          />
          <span>{" فندق مكّة"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={goodBadResponse}
            value={filterModel.makkahServiceReview}
            onChange={(makkahServiceReview) =>
              _setfilterModel((prev) => ({ ...prev, makkahServiceReview }))
            }
          />
          <span>{" خدمات مكّة"}</span>
        </div>
        <div
          style={{
            padding: 15,
          }}
        >
          <InputPicker
            style={{ width: 150 }}
            data={BestInKounouz}
            value={filterModel.bestOf}
            onChange={(bestOf) =>
              _setfilterModel((prev) => ({ ...prev, bestOf }))
            }
          />
          <span>{" مميّزات كنوز"}</span>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearch;
