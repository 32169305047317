import moment from "moment/moment";
import React from "react";

const ComponentToPrint = ({ data, generalStatistics }) => {
  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <img src="./Logo.png" width={200} height={200} />
        <h1 style={{ textAlign: "center" }}>Notes</h1>
        <h3>
          Date:
          {data.length > 0
            ? moment(
                data.find((el) => el.disponibility).disponibility.date
              ).format("DD/MM/YYYY")
            : moment(new Date()).format("DD/MM/YYYY")}
        </h3>
      </div>

      <p style={{}}>
        Hotel Makkah:
        <span
          style={{
            marginRight: 5,
            marginLeft: 5,
            fontWeight: "bold",
            fontSize: 17,
          }}
        >
          {data.find((el) => el.price)
            ? data.find((el) => el.price).price.hotelMakkahName || "-"
            : "-"}
        </span>
      </p>

      <p style={{}}>
        Hotel Madinah:
        <span
          style={{
            marginRight: 5,
            marginLeft: 5,
            fontWeight: "bold",
            fontSize: 17,
          }}
        >
          {data.find((el) => el.price)
            ? data.find((el) => el.price).price.hotelMadinahName || "-"
            : "-"}
        </span>
      </p>

      <p style={{}}>
        Airline:
        <span
          style={{
            marginRight: 5,
            marginLeft: 5,
            fontWeight: "bold",
            fontSize: 17,
          }}
        >
          {data.find((el) => el.disponibility && el.disponibility.airline)
            ? data.find((el) => el.disponibility && el.disponibility.airline)
                .disponibility.airline.name || "-"
            : "-"}
        </span>
      </p>

      <p style={{}}>
        Guide:
        <span
          style={{
            marginRight: 5,
            marginLeft: 5,
            fontWeight: "bold",
            fontSize: 17,
          }}
        >
          {data.find((el) => el.guideName)
            ? data.find((el) => el.guideName).guideName || "--"
            : "----"}
        </span>
      </p>

      {data.length != 0 &&
        data.map((order, index) => {
          return (
            <div
              style={{
                boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                padding: 15,
                marginTop: 10,
                background: "rgba(236, 240, 241,0.3)",
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontSize: 14 }}>
                  Name: {order.customer && order.customer.name}
                </p>
                <p style={{ fontSize: 14 }}>
                  Téléphone: {order.customer && order.customer.phoneNumber}
                </p>
                <p style={{}}>
                  Agence:
                  <span
                    style={{
                      marginRight: 5,
                      marginLeft: 5,
                    }}
                  >
                    {data.find((el) => el.client)
                      ? data.find((el) => el.client).client.name || "-"
                      : "-"}
                  </span>
                </p>
              </div>

              <p
                style={{
                  textAlign: "right",
                  whiteSpace: "pre-line",
                  backgroundColor: "white",
                  padding: 10,
                  fontSize: 17,
                }}
              >
                {order.surveyMadinah && order.surveyMadinah.note
                  ? order.surveyMadinah.note
                  : "no Note"}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default ComponentToPrint;
