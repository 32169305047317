import moment from "moment/moment";
import React from "react";

const ComponentToPrint = ({ data, total }) => {
  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <img src="./Logo.png" width={120} height={120} />
        <h1 style={{ textAlign: "center" }}>Notes</h1>
        <h3>
          Date:
          {data.length > 0
            ? moment(
                data.find((el) => el.disponibility).disponibility.date
              ).format("DD/MM/YYYY")
            : moment(new Date()).format("DD/MM/YYYY")}
        </h3>
      </div>
      <div
        className="p-10"
        style={{
          display: "flex",
          // alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div>
          <p style={{ fontWeight: "bold", fontSize: 15 }}>
            {total} :عدد المعتمرين
          </p>
          {/* <p>{answered}</p> */}
        </div>
      </div>
      {data.length != 0 &&
        data.map((order, index) => {
          return (
            <div
              style={{
                boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                padding: 15,
                marginTop: 10,
                background: "rgba(236, 240, 241,0.3)",
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontSize: 14, padding: 5 }}>
                  <span style={{ fontWeight: "bold" }}>Name:</span>
                  <span style={{ marginLeft: 5 }}>
                    {order.customer && order.customer.name}
                  </span>
                </p>
                <p style={{ fontSize: 14, padding: 5 }}>
                  <span style={{ fontWeight: "bold" }}> Téléphone: </span>
                  <span style={{ marginLeft: 5 }}>
                    {order.customer && order.customer.phoneNumber}
                  </span>
                </p>
                <p style={{ fontSize: 14, padding: 5 }}>
                  <span style={{ fontWeight: "bold" }}>Agence:</span>
                  <span style={{ marginLeft: 5 }}>
                    {order.client ? order.client.name : ""}
                  </span>
                </p>

                <p style={{ fontSize: 14, padding: 5 }}>
                  <span style={{ fontWeight: "bold" }}>Hotel Makkah:</span>
                  <span
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {order.price ? order.price.hotelMakkahName : ""}
                  </span>
                </p>

                <p style={{ fontSize: 14, padding: 5 }}>
                  <span style={{ fontWeight: "bold" }}>Hotel Madinah:</span>
                  <span
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {order.price ? order.price.hotelMadinahName : ""}
                  </span>
                </p>

                <p style={{ fontSize: 14, padding: 5 }}>
                  <span style={{ fontWeight: "bold" }}> Airline:</span>
                  <span
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {order.disponibility && order.disponibility.airline
                      ? order.disponibility.airline.name
                      : ""}
                  </span>
                </p>

                <p style={{ fontSize: 14, padding: 5 }}>
                  <span style={{ fontWeight: "bold" }}> Guide:</span>
                  <span
                    style={{
                      marginLeft: 5,
                    }}
                  >
                    {order.guideName ? order.guideName : "----"}
                  </span>
                </p>
              </div>
              <p
                style={{
                  textAlign: "right",
                  whiteSpace: "pre-line",
                  backgroundColor: "white",
                  padding: 10,
                  fontSize: 17,
                }}
              >
                {order.customer.survey && order.customer.survey.note
                  ? order.customer.survey.note
                  : "no Note"}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default ComponentToPrint;
