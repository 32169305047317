import React, { useEffect } from "react";
import { Checkbox, InputPicker, SelectPicker } from "rsuite";
import {
  BestInKounouz,
  branchTreatment,
  goodBadResponse,
  guide,
  heardOf,
  UmrahState,
  yesOrNoResponse,
} from "../../data/filterData";
import { GoodOrBadResponse, worstOf } from "../../data/data";

const AdvancedSearch = ({ filterModel, _setfilterModel }) => {
  const setTrueOrFalseValue = (value) => {
    if (value == 1) return true;
    else if (value == 2) return false;
    else return null;
  };
  const fixValue = (value) => {
    if (value == true) return 1;
    else if (value == false) return 2;
    else return null;
  };
  useEffect(() => console.log(filterModel), [filterModel]);
  return (
    <div>
      <div>
        <div
          className="p-10"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{
              padding: 15,
            }}
          >
            <SelectPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.umrahReview}
              onChange={(umrahReview) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  umrahReview,
                }))
              }
            />
            <span>{": أجواء العمرة   "}</span>
          </div>

          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.guideMakkah}
              onChange={(guideMakkah) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  guideMakkah,
                }))
              }
            />
            <span>{"  : المرافق في مكة  "}</span>
          </div>
          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.taifReview}
              onChange={(taifReview) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  taifReview,
                }))
              }
            />
            <span>{" : رحلة الطائف "}</span>
          </div>

          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.makkahReligiousbriefing}
              onChange={(makkahReligiousbriefing) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  makkahReligiousbriefing,
                }))
              }
            />
            <span>{": الاحاطة الدينية في مكة   "}</span>
          </div>

          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.makkahHotel}
              onChange={(makkahHotel) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  makkahHotel,
                }))
              }
            />
            <span>{" : فندق مكة   "}</span>
          </div>

          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={yesOrNoResponse}
              value={fixValue(filterModel.makkahUsedMed)}
              onChange={(makkahUsedMed) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  makkahUsedMed: setTrueOrFalseValue(makkahUsedMed),
                }))
              }
            />
            <span>{"  استحقيت لطبيب ؟"}</span>
          </div>
          {filterModel.medinahUsedMed && (
            <div
              style={{
                padding: 15,
              }}
            >
              <InputPicker
                style={{ width: 150 }}
                data={goodBadResponse}
                value={filterModel.makkahMedicTreatment}
                onChange={(makkahMedicTreatment) =>
                  _setfilterModel((prev) => ({
                    ...prev,
                    makkahMedicTreatment,
                  }))
                }
              />
              <span>{" :معاملة الطبيب "}</span>
            </div>
          )}
          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={worstOf}
              value={filterModel.worstOfMakkah}
              onChange={(worstOfMakkah) =>
                _setfilterModel((prev) => ({ ...prev, worstOfMakkah }))
              }
            />
            <span>
              {" الحاجة اللي ما عجبتكش في خدمات كنوز طول فترة اقامتك في مكة "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearch;
