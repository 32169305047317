import React, { useEffect } from "react";
import { Checkbox, InputPicker, SelectPicker } from "rsuite";
import {
  BestInKounouz,
  branchTreatment,
  goodBadResponse,
  guide,
  heardOf,
  UmrahState,
  yesOrNoResponse,
} from "../../data/filterData";
import { GoodOrBadResponse, worstOf } from "../../data/data";

const AdvancedSearch = ({ filterModel, _setfilterModel }) => {
  const setTrueOrFalseValue = (value) => {
    if (value == 1) return true;
    else if (value == 2) return false;
    else return null;
  };
  const fixValue = (value) => {
    if (value == true) return 1;
    else if (value == false) return 2;
    else return null;
  };
  return (
    <div>
      <div>
        <div
          className="p-10"
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{
              padding: 15,
            }}
          >
            <SelectPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.organizationInTunisia}
              onChange={(organizationInTunisia) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  organizationInTunisia,
                }))
              }
            />
            <span>{" لتنظيم قبل الرحلة في مطار تونس و في طيّارة"}</span>
          </div>

          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.organizationInSaoudi}
              onChange={(organizationInSaoudi) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  organizationInSaoudi,
                }))
              }
            />
            <span>
              {" التنظيم و الاستقبال في المطار عند الوصول الى السعودية"}
            </span>
          </div>
          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.guideReview}
              onChange={(guideReview) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  guideReview,
                }))
              }
            />
            <span>{" : المرافق "}</span>
          </div>

          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.madinahPlacesReview}
              onChange={(madinahPlacesReview) =>
                _setfilterModel((prev) => ({ ...prev, madinahPlacesReview }))
              }
            />
            <span>{": مزارات المدينة  "}</span>
          </div>

          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.madinahReligiousbriefing}
              onChange={(madinahReligiousbriefing) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  madinahReligiousbriefing,
                }))
              }
            />
            <span>{" :الاحاطة الدينية "}</span>
          </div>
          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={GoodOrBadResponse}
              value={filterModel.madinahHotelReview}
              onChange={(madinahHotelReview) =>
                _setfilterModel((prev) => ({ ...prev, madinahHotelReview }))
              }
            />
            <span>{" :فندق المدينة  "}</span>
          </div>

          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={yesOrNoResponse}
              value={fixValue(filterModel.medinahUsedMed)}
              onChange={(medinahUsedMed) =>
                _setfilterModel((prev) => ({
                  ...prev,
                  medinahUsedMed: setTrueOrFalseValue(medinahUsedMed),
                }))
              }
            />
            <span>{"  استحقيت لطبيب ؟"}</span>
          </div>
          {filterModel.medinahUsedMed && (
            <div
              style={{
                padding: 15,
              }}
            >
              <InputPicker
                style={{ width: 150 }}
                data={goodBadResponse}
                value={filterModel.medinahMedicTreatment}
                onChange={(medinahMedicTreatment) =>
                  _setfilterModel((prev) => ({
                    ...prev,
                    medinahMedicTreatment,
                  }))
                }
              />
              <span>{" :معاملة الطبيب "}</span>
            </div>
          )}
          <div
            style={{
              padding: 15,
            }}
          >
            <InputPicker
              style={{ width: 150 }}
              data={worstOf}
              value={filterModel.worstOfMadinah}
              onChange={(worstOfMadinah) =>
                _setfilterModel((prev) => ({ ...prev, worstOfMadinah }))
              }
            />
            <span>
              {
                " الحاجة اللي ما عجبتكش في خدمات كنوز طول فترة اقامتك في المدينة؟ "
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearch;
