import React, { useEffect, useRef, useState } from "react";

import { ToastContainer } from "react-toastify";
import { useRecoilState } from "recoil";
import { Input, InputPicker, Pagination } from "rsuite";
import Swal from "sweetalert2";
import { createAPIEndpoint } from "../../API/authenticated.request";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { surveyStateAtom } from "../../Atoms/surveyState.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid/index";
import Responsive from "../../Components/Responsive";
import { Base_URL2 } from "../../Config/api.config";
import { CustomerModel } from "../../Models/Customer";
import { MakkahSurveyModel } from "../../Models/MakkahSurveyModel";
import { surveySaoudiState } from "../../data/data";
import AddEdit from "./AddEdit";
import AddSurvey from "./AddSurvey";
import moment from "moment";
import StatButton from "../../Components/Stat/StatButton";
import ComponentToPrint from "./ComponentToPrint";
import AdvancedSearch from "./AdvancedSearch";
import { testEmpty } from "../../Components/testEmptyResponse";

const SurveyMakkah = () => {
  const [dataMootmars, setdataMootmars] = useState([]);
  const [dates, setdates] = useState([]);
  const [clients, setclients] = useState([]);
  const [state, setstate] = useRecoilState(exportAddAtom);
  const [model, setmodel] = useState(new CustomerModel());

  //------------------------------------------------------------------------------------------------//
  const [SurveyModel, setSurveyModel] = useState(new MakkahSurveyModel());
  const [Error, setError] = useState("");
  const [Seasons, setSeasons] = useState([]);
  const [filterModel, setfilterModel] = useState({
    q: "",
    disponibilityId: 0,
    seasonId: 0,
    clientId: 0,
    makkahRoomNumber: 0,
    madinahRoomNumber: 0,
    page: 1,
    take: 50,
    umrahReview: 0,
    guideMakkah: 0,
    taifReview: 0,
    makkahReligiousbriefing: 0,
    makkahHotel: 0,
    worstOfMakkah: null,
    makkahMedicTreatment: 0,
    makkahUsedMed: null,
  });

  const [surveyState, setsurveyState] = useRecoilState(surveyStateAtom);
  const [totalCount, settotalCount] = useState(0);
  const [GuideNumbers, setGuideNumbers] = useState([]);
  const [notDone, setnotDone] = useState(null);
  const [showNotDone, setshowNotDone] = useState(false);

  const checkGuide = (v) => {
    if (GuideNumbers.includes(v.phoneNumber)) {
      return { color: "red", fontSize: 17 };
    } else {
      return { color: "green", fontSize: 17 };
    }
  };

  const getSeasons = () => {
    createAPIEndpoint("Season", { page: 1, take: 1000 })
      .fetch()
      .then((res) => {
        setSeasons(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const CustomerType = (v) => {
    if (v == 1)
      return (
        <span
          style={{
            backgroundColor: "green",
            color: "white",
            textAlign: "center",
            padding: "2px 8px",
            borderRadius: 5,
            fontSize: 15,
          }}
        >
          Client
        </span>
      );
    else if (v == 2)
      return (
        <span
          style={{
            backgroundColor: "orange",
            color: "white",
            textAlign: "center",
            padding: "2px 8px",
            borderRadius: 5,
            fontSize: 15,
          }}
        >
          Guide
        </span>
      );
    else if (v == 3)
      return (
        <span
          style={{
            backgroundColor: "red",
            color: "white",
            textAlign: "center",
            padding: "2px 8px",
            borderRadius: 5,
            fontSize: 15,
          }}
        >
          Employé
        </span>
      );
  };

  const columns = [
    {
      value: "customer",
      render: (v) => {
        return (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              <img
                src={Base_URL2 + "Uploads/CustomerPictures/" + v.picture}
                height={35}
                width={35}
                style={{
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              />
            }
          </p>
        );
      },
    },
    {
      name: "Nom",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 15, fontWeight: "bold" }}>{v.name}</p>;
      },
    },
    {
      name: "Type",
      value: "customer",
      render: (v) => {
        return <p style={{ fontSize: 15 }}>{CustomerType(v.customerType)}</p>;
      },
    },

    {
      name: "Date de Vol",
      value: "disponibility",
      render: (v) => {
        return (
          <p style={{ fontSize: 15 }}>
            {v ? moment(v.date).format("DD/MM/YYYY") : ""}
          </p>
        );
      },
    },
    {
      name: "Branche",
      value: "client",
      render: (v) => {
        return <p style={{ fontSize: 15 }}>{v.name.substring(8)}</p>;
      },
    },
    {
      name: "Téléphone",
      value: "customer",
      render: (v) => {
        return (
          <p style={checkGuide(v)}>{v.phoneNumber ? v.phoneNumber : "--"}</p>
        );
      },
    },
    {
      name: "Survey",
      value: "surveyMakkahState",
      render: (v) => {
        let x = surveySaoudiState.find((el) => el.value == v);
        return (
          <div style={surveyStateColor(v)}>
            <p style={{ fontSize: 15 }}>{x ? x.label : ""}</p>
          </div>
        );
      },
    },
  ];
  const surveyStateColor = (v) => {
    if (v == 1) {
      return {
        backgroundColor: "#85ea2d",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 2) {
      return {
        backgroundColor: "grey",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 3) {
      return {
        backgroundColor: "black",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 4) {
      return {
        backgroundColor: "orange",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    } else if (v == 5) {
      return {
        backgroundColor: "red",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    }
  };
  //----------------get data
  const getData = () => {
    createAPIEndpoint("/Order/UmrahCrm", filterModel)
      .fetch()
      .then((res) => {
        setdataMootmars(res.data.data);
        settotalCount(res.data.totalCount);
      })
      .then(() => {
        if (filterModel.disponibilityId) {
          createAPIEndpoint("/Order/UmrahCrm", {
            q: "",
            seasonId: 0,
            disponibilityId: filterModel.disponibilityId,
            clientId: 0,
            page: 1,
            take: 500,
          })
            .fetch()
            .then((res) => {
              setnotDone({
                label: "",
                value: res.data.data.filter(
                  (el) =>
                    el.customer.customerType == 1 && el.surveyMakkahState == 0
                ).length,
              });
              setshowNotDone(true);
            });
        } else setshowNotDone(false);
      })
      .catch((err) => console.log(err));
  };
  //--------------------------------reset
  const reset = () => {
    setError("");
    setmodel(new CustomerModel());
    setSurveyModel(new MakkahSurveyModel());
    setstate((prev) => {
      return { ...prev, open: false, loading: false };
    });
    setsurveyState((prev) => {
      return { ...prev, open: false, loading: false };
    });
  };
  //////////--------------------------- save Customer
  const save = () => {
    let modelCopy = { ...model };

    delete modelCopy.disponibility;
    delete modelCopy.client;
    delete modelCopy.price;
    delete modelCopy.packageUmrah;
    createAPIEndpoint("Order/Umrah")
      .update(modelCopy)
      .then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: ". تمّ التغير بنجاح ",
          showConfirmButton: false,
          timer: 1700,
        });
        getData();
        reset();
      })
      .catch((err) => console.log(err));
  };
  //-----------------SaveData from survey
  const saveDataFromSurvey = () => {
    let modelCopy = { ...model };

    delete modelCopy.disponibility;
    delete modelCopy.client;
    delete modelCopy.price;
    delete modelCopy.packageUmrah;
    createAPIEndpoint("Order/Umrah")
      .update(modelCopy)
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: ". تمّ التغير بنجاح ",
          showConfirmButton: false,
          timer: 1700,
        });
        getData();
      })
      .catch((err) => console.log(err));
  };
  ///////////////------------------------------Save Survey
  const saveSurvey = () => {
    if (SurveyModel.surveyState == 1 || SurveyModel.surveyState == 2) {
      if (
        !testEmpty(SurveyModel, new MakkahSurveyModel(), [
          "chamberNumber",
          "chamberType",
          "note",
          "surveyState",
        ]) &&
        SurveyModel.surveyState == 1
      ) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "!! اكمل الاستبيان ",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      let survey = { ...SurveyModel };
      if (SurveyModel.surveyState == 2) {
        survey = {
          ...SurveyModel,
          worstOfMakkah: 0,
          useMed: false,
        };
      }
      if (!SurveyModel.id) {
        createAPIEndpoint("SurveyMakkah")
          .create({
            ...survey,
            disponibilityId: model.disponibilityId,
            orderUmrahId: model.id,
            clientId: model.client.id,
            dateVol: model.disponibility.date,
            guideName: model.guideName,
            hotelMakkah: model.price ? model.price.hotelMakkahName : "",
            hotelMadinah: model.price ? model.price.hotelMadinahName : "",
            createdBy: JSON.parse(localStorage.getItem("auth")).userName,
            createdDate: new Date(),
          })
          .then(() => {
            reset();
            getData();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: ". تمّ التغير بنجاح ",
              showConfirmButton: false,
              timer: 1700,
            });
          })
          .catch((err) => console.log(err));
      } else {
        createAPIEndpoint("surveyMakkah")
          .updateWithId(SurveyModel.id, {
            ...survey,
            disponibilityId: model.disponibilityId,
            updatedBy: JSON.parse(localStorage.getItem("auth")).userName,
            updatedDate: new Date(),
          })
          .then(() => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: ". تمّ التغير بنجاح ",
              showConfirmButton: false,
              timer: 1700,
            });
            reset();
            getData();
          })
          .catch((err) => console.log(err));
      }
    } else
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "!! ضع حالة الاستبيان ",
        showConfirmButton: false,
        timer: 1700,
      });
  };
  const getById = (id) => {
    createAPIEndpoint(`/Order/Umrah`)
      .fetchById(id)
      .then((res) => {
        setmodel(res.data);

        res.data.surveyMakkahState == 1
          ? createAPIEndpoint("SurveyMakkah/GetByOrderUmrahId")
              .fetchById(id)
              .then((resp) => {
                setSurveyModel(resp.data);
              })
              .catch((err) => console.log(err))
          : setSurveyModel({
              ...SurveyModel,
              surveyState: res.data.surveyMakkahState,
              note: res.data.noteMakkah,
            });
      })
      .catch((err) => console.log(err));
  };
  const getDates = () => {
    createAPIEndpoint("PackageUmrah/getForPrices", {
      q: "",
      disponibilityId: 0,
      page: 1,
      take: 200,
    })
      .fetch()
      .then((res) => {
        let filteredDates = res.data.data.filter((date) =>
          filterModel.seasonId ? date.seasonId == filterModel.seasonId : date
        );
        setdates(
          filteredDates
            .reduce(
              (accumulator, el) => accumulator.concat(el.disponibilities),
              []
            )
            .map((el) => ({
              label: el.airline
                ? moment(el.date).format("DD/MM/YYYY") +
                  " -- " +
                  el.airline.fullName
                : "",
              value: el.id,
            }))
        );
      })
      .catch((err) => console.log(err));
  };

  ////------------------------Clients--------------------///
  const getClients = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        setclients(
          res.data.map((el) => ({
            label: el.name,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  const getGuidesNumbers = () => {
    createAPIEndpoint("Guide/getAll")
      .fetchAll()
      .then((res) => {
        let numbers = [];
        res.data
          .map((el) => {
            return el.phoneNumber;
          })
          .map((el) => (numbers = [...numbers, ...el.split("/")]));

        setGuideNumbers(numbers);
      })

      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
    getClients();
    getSeasons();
    getGuidesNumbers();
  }, []);
  useEffect(() => getDates(), [filterModel.seasonId]);

  return (
    <React.Fragment>
      <ToastContainer />
      <div>
        <div style={{ width: "99%", margin: "auto", marginTop: "12px" }}>
          <Filter
            search={getData}
            advanced
            advancedComponent={
              <AdvancedSearch
                filterModel={filterModel}
                _setfilterModel={setfilterModel}
                s
              />
            }
          >
            <Responsive l={3} xl={3} className="p-10">
              <label>Nom ou Téléphone: </label>
              <Input
                value={filterModel.q}
                placeholder="Rechercher"
                onChange={(q) => {
                  setfilterModel((prev) => {
                    return { ...prev, q, page: 1 };
                  });
                }}
              />
            </Responsive>
            <Responsive l={3} xl={3} className="p-10">
              <label> Date de Vol: </label>
              <InputPicker
                block
                placeholder="Rechercher"
                data={dates}
                onChange={(disponibilityId) => {
                  setfilterModel((prev) => {
                    return { ...prev, disponibilityId, page: 1 };
                  });
                }}
              />
            </Responsive>
            <Responsive l={3} xl={3} className="p-10">
              <label>Saison: </label>
              <InputPicker
                block
                data={[{ label: "Tout", value: 0 }].concat(
                  Seasons.map((c) => {
                    return { label: c.designation, value: c.id };
                  })
                )}
                value={filterModel.seasonId}
                placeholder="Rechercher"
                onChange={(seasonId) => {
                  setfilterModel((prev) => {
                    return { ...prev, seasonId, page: 1 };
                  });
                }}
              />
            </Responsive>
            <Responsive l={3} xl={3} className="p-10">
              <label> Agence: </label>
              <InputPicker
                block
                placeholder="Rechercher"
                data={clients}
                onChange={(clientId) => {
                  setfilterModel((prev) => {
                    return { ...prev, clientId, page: 1 };
                  });
                }}
              />
            </Responsive>
            {filterModel.disponibilityId != 0 && (
              <Responsive m={2} l={2} xl={2} className="p-10">
                <label> N° de chambre: </label>
                <Input
                  placeholder="Rechercher"
                  value={filterModel.makkahRoomNumber}
                  onChange={(makkahRoomNumber) => {
                    makkahRoomNumber
                      ? setfilterModel((prev) => {
                          return {
                            ...prev,
                            makkahRoomNumber: parseInt(makkahRoomNumber),
                          };
                        })
                      : setfilterModel((prev) => {
                          return {
                            ...prev,
                            makkahRoomNumber: 0,
                          };
                        });
                  }}
                />
              </Responsive>
            )}
          </Filter>
        </div>
      </div>
      {showNotDone && (
        <Responsive m={2} l={2} xl={2} className="p-10">
          <StatButton data={notDone} backgroundColor="#e1b12c" />
        </Responsive>
      )}
      <ExportAdd
        pdfDownload
        pdfTitle={"Note"}
        pdfComponent={
          <ComponentToPrint
            data={dataMootmars
              .filter((el) => el.surveyMakkah)
              .filter((el) => el.surveyMakkah.note)}
          />
        }
        title="آراء المعتمرين"
        ActionOnClose={reset}
        save={save}
        saveSurvey={saveSurvey}
        size="full"
        noExport
        noimportExcel
        AddComponent={
          <AddEdit error={Error} model={model} _setmodel={setmodel} />
        }
        AddsurveyComponent={
          <AddSurvey
            selectedMootamar={model}
            setselectedMootmar={setmodel}
            model={SurveyModel}
            _setmodel={setSurveyModel}
            update={saveDataFromSurvey}
          />
        }
      />
      <div style={{ width: "99%", margin: "auto" }}>
        <Grid
          actionKey="id"
          checkAction={(id) => {
            getById(id);
            setstate((prev) => {
              return { ...prev, open: true };
            });
          }}
          addServeyAction={(id) => {
            getById(id);
            setsurveyState((prev) => {
              return { ...prev, open: true };
            });
          }}
          columns={columns}
          noAdvancedActions={true}
          rows={dataMootmars}
        />
        <div style={{ padding: 20, background: "#fff" }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={["total", "-", "limit", "|", "pager", "skip"]}
            total={totalCount}
            limitOptions={[10, 20, 50, 100]}
            limit={filterModel.take}
            activePage={filterModel.page}
            onChangePage={(page) => {
              setfilterModel((prev) => {
                return { ...prev, page };
              });
              createAPIEndpoint("/Order/Umrah", {
                ...filterModel,
                page,
              })
                .fetch()
                .then((res) => {
                  setdataMootmars(res.data.data);
                  settotalCount(res.data.totalCount);
                })
                .catch((err) => console.log(err));
            }}
            onChangeLimit={(take) => {
              setfilterModel((prev) => {
                return { ...prev, take, page: 1 };
              });
              createAPIEndpoint("/Order/Umrah", {
                ...filterModel,
                take,
                page: 1,
              })
                .fetch()
                .then((res) => {
                  setdataMootmars(res.data.data);
                  settotalCount(res.data.totalCount);
                })
                .catch((err) => console.log(err));
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SurveyMakkah;
