export class MakkahSurveyModel {
  umrahReview = 0;
  guideMakkah = 0;
  taifReview = 0;
  makkahReligiousbriefing = 0;
  makkahHotel = 0;
  worstOfMakkah = null;
  useMed = null;
  medicTreatment = 0;
  chamberNumber = 0;
  chamberType = 0;
  note = "";
  surveyState = 0;
}
