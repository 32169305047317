import React, { useEffect, useState } from "react";
import Filter from "../../Components/Common/Filter";
import Responsive from "../../Components/Responsive";
import { DateRangePicker, IconButton, Input, InputPicker } from "rsuite";
import Stat from "../../Components/Stat/Stat";
import { Colors, Titles, worstColors } from "../../data/MadinahStatData";
import { createAPIEndpoint } from "../../API/authenticated.request";
import { goodBadResponse, yesOrNoResponse } from "../../data/dashboardData";
import { worstOf } from "../../data/data";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { Chart as ChartJS } from "chart.js";
import ReactDOMServer from "react-dom/server";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import Pdf from "./Pdf";

const MadinahStat = () => {
  const [filterModel, setfilterModel] = useState({
    q: "",
    dateVol: "",
    startDate: null,
    disponibilityId: 0,
    endDate: null,
    clientId: 0,
  });

  const [data, setdata] = useState([]);
  const [dataKey, setdataKey] = useState([]);
  const [date, setdates] = useState([]);
  const [Seasons, setSeasons] = useState([]);
  const [clients, setclients] = useState([]);
  const [Answered, setAnswered] = useState(0);
  const getData = () => {
    createAPIEndpoint("Statistics/MadinahStat", filterModel)
      .fetch()
      .then((res) => {
        setAnswered(res.data.answered);
        delete res.data.answered;
        setdata(res.data);
        setdataKey(Object.keys(res.data));
      })
      .catch((err) => console.log(err));
  };
  /////-------------dates--------------/////
  const getSeasons = () => {
    createAPIEndpoint("Season", { page: 1, take: 1000 })
      .fetch()
      .then((res) => {
        setSeasons(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  const getClients = () => {
    createAPIEndpoint("Client/getAll")
      .fetchAll()
      .then((res) => {
        setclients(
          res.data.map((el) => ({
            label: el.name,
            value: el.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  const getDates = () => {
    createAPIEndpoint("PackageUmrah/getForPrices", {
      q: "",
      disponibilityId: 0,
      page: 1,
      take: 200,
    })
      .fetch()
      .then((res) => {
        setdates(
          res.data.data
            .reduce(
              (accumulator, el) => accumulator.concat(el.disponibilities),
              []
            )
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .map((el) => ({
              label: el.airline
                ? moment(el.date).format("DD/MM/YYYY") +
                  " -- " +
                  el.airline.fullName
                : "",
              value: el.id,
            }))
        );
      })
      .catch((err) => console.log(err));
  };
  //////----------------
  const labels = (index) => {
    if (dataKey[index] == "useMed")
      return yesOrNoResponse.map((el) => {
        return el.label;
      });
    if (dataKey[index] != "worstOfMadinah")
      return goodBadResponse.map((el) => el.label).reverse();
    else return worstOf.map((el) => el.label);
  };
  //------------------------------------------
  const DownloadPDF = () => {
    let stat = [];
    Titles.map((element, index) => {
      const canvas = document.getElementById(`bar${index}`);
      console.log(canvas);
      let title = Titles[index];
      if (canvas) {
        const chartInstance = ChartJS.getChart(canvas);
        if (chartInstance) {
          const base64Image = chartInstance.toBase64Image();

          stat = [...stat, { label: title, image: base64Image }];
        }
      }
    });

    const content = ReactDOMServer.renderToStaticMarkup(
      <Pdf stat={stat} title={"Madinah Statistiques"} />
    );

    const documentContent = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Statistiques</title>
          <style>
            body { font-family: Arial, sans-serif; -webkit-print-color-adjust: exact; 
        print-color-adjust: exact;  
         }
         @media print {
            @page {
              size: A4;        
              }
           
              }
  
        
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>
    `;

    // Create a hidden iframe to inject and download as a file
    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0px";
    iframe.style.height = "0px";
    iframe.style.border = "none";
    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();

    doc.write(documentContent);
    doc.close();

    // Wait until the iframe content is loaded before triggering print
    iframe.onload = () => {
      // You could do any final checks here if needed, like checking if images are fully loaded inside iframe
      iframe.contentWindow.print();
      // Remove the iframe after printing
      iframe.remove();
    };
  };
  //----------------------------------------

  useEffect(() => {
    getData();
    getDates();
    getSeasons();
    getClients();
  }, []);
  // useEffect(() => console.log("datakey", dataKey), [dataKey]);
  return (
    <div>
      <Filter search={getData}>
        <Responsive l={3} xl={3} className="p-10">
          <label htmlFor="">Date:</label>
          <InputPicker
            block
            data={date}
            value={filterModel.disponibilityId}
            onChange={(disponibilityId) =>
              setfilterModel((prev) => ({ ...prev, disponibilityId }))
            }
          />
        </Responsive>
        <Responsive l={3} xl={3} className="p-10">
          <label htmlFor="">Agence:</label>
          <InputPicker
            block
            data={clients}
            value={filterModel.clientId}
            onChange={(clientId) =>
              setfilterModel((prev) => ({ ...prev, clientId }))
            }
          />
        </Responsive>
        <Responsive l={3} xl={3} className="p-10">
          <label>Plage de dates: </label>
          <DateRangePicker
            block
            value={filterModel.dateRange}
            // placeholder="Rechercher"
            onChange={(q) =>
              q
                ? setfilterModel((prev) => ({
                    ...prev,
                    startDate: q[0],
                    endDate: q[1],
                  }))
                : setfilterModel((prev) => ({
                    ...prev,
                    dateRange: "",
                    startDate: "",
                    endDate: "",
                  }))
            }
          />
        </Responsive>
        {/* <Responsive l={3.5} xl={3.5} className="p-10">
          <label htmlFor="">Date:</label>
          <Input />
        </Responsive> */}
      </Filter>
      <Responsive className="p-10" style={{ textAlign: "right" }}>
        <IconButton
          appearance="primary"
          color="red"
          icon={<FileDownloadIcon />}
          onClick={DownloadPDF}
        >
          Download PDF
        </IconButton>
      </Responsive>

      {data.length != 0 ? (
        dataKey.map((element, index) => {
          let Labels = labels(index);
          return (
            <Responsive
              l={6}
              xl={6}
              className="p-10"
              style={{ float: "right" }}
            >
              <Stat
                number={index}
                title={Titles[index]}
                data={data[dataKey[index]]}
                labels={Labels}
                backgroundColors={
                  dataKey[index] != "worstOfMadinah" ? Colors : worstColors
                }
                Answered={Answered}
              />
            </Responsive>
          );
        })
      ) : (
        <div>
          <Responsive xl={6} l={6} className="p-10 ">
            <Skeleton variant="rounded" animation="wave" height="40vh" />
          </Responsive>
          <Responsive xl={6} l={6} className="p-10 ">
            <Skeleton variant="rounded" animation="wave" height="40vh" />
          </Responsive>
          <Responsive xl={6} l={6} className="p-10 ">
            <Skeleton variant="rounded" animation="wave" height="40vh" />
          </Responsive>
          <Responsive xl={6} l={6} className="p-10 ">
            <Skeleton variant="rounded" animation="wave" height="40vh" />
          </Responsive>
          <Responsive xl={6} l={6} className="p-10 ">
            <Skeleton variant="rounded" animation="wave" height="40vh" />
          </Responsive>
          <Responsive xl={6} l={6} className="p-10 ">
            <Skeleton variant="rounded" animation="wave" height="40vh" />
          </Responsive>
        </div>
      )}
    </div>
  );
};

export default MadinahStat;
