export const Titles = [
  "أجواء العمرة",
  "المرافق",
  "رحلة الطائف",
  "الإحاطة الدينية في مكة",
  " فندق مكة",
  "احتياج الطبيب",
  " الرضاء على خدمات الطبيب ",
  "أسوء الخدمات في مكة",
];
export const Colors = [
  "#e74c3c",
  "#7f8c8d",
  "#27ae60",
  "#2980b9",
  "orange",
  "Purple",
  "Teal",
];
export const worstOf = [
  { label: "لا شيئ", value: 0 },
  { label: "التنظيم و الاستقبال", value: 1 },
  { label: ": رحلة الطائف ", value: 5 },
  { label: "فندق ", value: 2 },
  { label: "المرافق", value: 4 },
  { label: "الاحاطة الدينية ", value: 3 },
];
export const worstColors = [
  "#26de81",
  "#7f8c8d",
  "#fa8231",
  "#4b7bec",
  "orange",
  "Purple",
  "Teal",
];
