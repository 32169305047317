import React from "react";
import Responsive from "../../Components/Responsive";
import moment from "moment";

const Pdf = ({ generalImage, stat, title }) => {
  return (
    <div style={{ position: "relative" }}>
      <img
        style={{ position: "absolute", top: -35, left: 0 }}
        src="./Logo.png"
        alt=""
        width={120}
        height={120}
      />
      <p style={{ fontWeight: "bold", fontSize: 25, textAlign: "center" }}>
        {title}
      </p>
      {/* <span>{moment(Date()).format("DD/MM/YYYY")}</span> */}

      {generalImage && (
        <Responsive className="p-10">
          <img src={generalImage} />
        </Responsive>
      )}
      {stat &&
        stat.map((el) => (
          <div className="p-10 " style={{ width: "50%", float: "right" }}>
            <div
              style={{
                background: "white",
                textAlign: "center",
                width: "100%",
              }}
            >
              <p style={{ fontWeight: 500, fontSize: 35 }}>{el.label}</p>
              <img src={el.image} alt="" width={"100%"} height={"100%"} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Pdf;
